import {
  createSlice,
  isAnyOf,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { AxiosClient } from "redux/AxiosClient";

const initialState = {
  itineraryLoader: false,
  itinerary: {},
  bestHotelLoader: false,
  bestHotels: [],
  bestFoodItemsLoader: false,
  bestFoodItems: [],
  assisstentLoader: false,
  assisstentIn: [],
  downloadPDFLoader: false,
  downloadPDF: null,
};

export const ItinerarySlice = createSlice({
  name: "Itinerary",
  initialState,
  extraReducers: (builder) => {
    // GET ITINERARY
    builder.addMatcher(isAnyOf(getItineraryAsync.pending), (state) => {
      state.itineraryLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getItineraryAsync.fulfilled),
      (state, action) => {
        state.itineraryLoader = false;
        state.itinerary = action.payload.data;
      }
    );
    builder.addMatcher(isAnyOf(getItineraryAsync.rejected), (state, action) => {
      state.itineraryLoader = false;
    });
    // DOWNLOAD PDF
    builder.addMatcher(isAnyOf(downloadPDFAsync.pending), (state) => {
      state.downloadPDFLoader = true;
    });
    builder.addMatcher(isAnyOf(downloadPDFAsync.fulfilled), (state, action) => {
      state.downloadPDFLoader = false;
      state.downloadPDF = action.payload;
    });
    builder.addMatcher(isAnyOf(downloadPDFAsync.rejected), (state, action) => {
      state.downloadPDFLoader = false;
    });
    // GET HOTELS
    builder.addMatcher(isAnyOf(getBestHotelsAsync.pending), (state) => {
      state.bestHotelLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getBestHotelsAsync.fulfilled),
      (state, action) => {
        state.bestHotelLoader = false;
        state.bestHotels = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getBestHotelsAsync.rejected),
      (state, action) => {
        state.bestHotelLoader = false;
      }
    );
    // GET FOOD ITEMS
    builder.addMatcher(isAnyOf(getBestFoodItemsAsync.pending), (state) => {
      state.bestFoodItemsLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getBestFoodItemsAsync.fulfilled),
      (state, action) => {
        state.bestFoodItemsLoader = false;
        state.bestFoodItems = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getBestFoodItemsAsync.rejected),
      (state, action) => {
        state.bestFoodItemsLoader = false;
      }
    );
    // ASSISSTENT
    builder.addMatcher(isAnyOf(assisstentAsync.pending), (state) => {
      state.assisstentLoader = true;
    });
    builder.addMatcher(isAnyOf(assisstentAsync.fulfilled), (state, action) => {
      state.assisstentIn.unshift({
        role: "assistant",
        content: action.payload.data.content,
      });
      state.assisstentLoader = false;
    });
    builder.addMatcher(isAnyOf(assisstentAsync.rejected), (state, action) => {
      state.assisstentLoader = false;
    });
  },
  reducers: {
    addPushItinerary: (state, action) => {
      const initialIn = _.map(action.payload, (item) => {
        return {
          ...item,
          bestHotels: [],
          bestFoodItems: [],
          bestRestaurents: [],
        };
      });
      state.itinerary = initialIn;
    },
    addPushAssistent: (state, action) => {
      state.assisstentIn.unshift({
        role: action.payload.role,
        content: action.payload.content,
      });
    },
    emptyBestHotels: (state) => initialState,
    emptyItinerary: (state) => initialState,
    emptyBestHotels: (state) => initialState,
  },
});
export const {
  emptyItinerary,
  emptyBestHotels,
  addPushItinerary,
  addPushAssistent,
} = ItinerarySlice.actions;

export default ItinerarySlice.reducer;

export const getItineraryAsync = createAsyncThunk(
  "admin/getItinerary",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/gpt/generate-itinerary`,
      payload,
      toolkit
    );
  }
);

export const getBestHotelsAsync = createAsyncThunk(
  "admin/getBestHotels",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/gpt/getBestHotels`, payload, toolkit);
  }
);

export const getBestFoodItemsAsync = createAsyncThunk(
  "admin/getBestFoodItems",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/gpt/getBestFoodItems`, payload, toolkit);
  }
);
export const assisstentAsync = createAsyncThunk(
  "admin/assistent",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/gpt/assistent`, payload, toolkit);
  }
);

export const downloadPDFAsync = createAsyncThunk(
  "admin/downloadPDFAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/generate-pdf`, payload, toolkit);
  }
);
