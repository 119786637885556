import React, { useState } from "react";
import {
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { inputstyle } from "assets/makestyles/input";
import { EnterOutlined } from "@ant-design/icons";
import { addPushAssistent, assisstentAsync } from "redux/chatgpt/chatgpt";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import BarProgress from "components/BarProgress/index";
import { config } from "config/config";
import bgImage from "../../assets/bg.png";
import { useNavigate } from "react-router-dom";

const AssistantGPT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const downsm = useMediaQuery(theme.breakpoints.down("sm"));
  const inputstyleIn = inputstyle();

  const [prompt, setPrompt] = useState("");

  const { assisstentLoader, assisstentIn } = useSelector(
    (state) => state.Itinerary
  );

  const handleAssistent = (e) => {
    e.preventDefault();
    if (Boolean(prompt)) {
      const _userPrompt = {
        role: "user",
        content: _.startCase(prompt),
      };
      let _value = _.map(assisstentIn, (item) => item);
      _value = _.reverse(_value);
      dispatch(
        assisstentAsync({
          _old: _value,
          prompt,
        })
      );
      dispatch(addPushAssistent(_userPrompt));
      setPrompt("");
    }
  };
  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              width: "1202px",
              borderRadius: "5px",
              opacity: 4,
              backgroundColor: "#585858b7",
              marginTop: "50px",
              padding: "20px",
              height: "70vh",
              overflow: "scroll",
            }}
          >
            <form onSubmit={handleAssistent}>
              <TextField
                placeholder={`Ask anything about the ${config.LOCATION}`}
                fullWidth
                sx={{
                  border: "1px solid white",
                  backgroundColor: "#2e2e33",
                  borderRadius: "6px",
                  mt: "10px",
                }}
                value={prompt}
                className={inputstyleIn.root}
                onChange={(e) => setPrompt(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {Boolean(prompt) && (
                        <IconButton onClick={handleAssistent}>
                          <EnterOutlined
                            style={{
                              color: "white",
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            {assisstentLoader && (
              <Box sx={{ mt: 2 }}>
                <BarProgress />
              </Box>
            )}
            {_.map(
              [
                ...assisstentIn,
                {
                  role: "assistant",
                  content: `Hello! How may I assist you today? As an assistant, I have extensive knowledge about ${config.LOCATION}. Just ask me anything about ${config.LOCATION}.`,
                },
              ],
              (item, index) => {
                const _iswho = Boolean(item.role === "assistant");
                const _length = Boolean(item?.content?.length <= 94);
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        backgroundColor: "#2e2e33",
                        px: "16px",
                        py: "20px",
                        mt: "20px",
                        borderRadius: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{ bgcolor: "#1d1e20", width: 60, height: 60 }}
                      >
                        {_iswho ? "AI" : "YOU"}
                      </Avatar>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#dadadb",
                          fontWeight: "500",
                          fontSize: {
                            xs: "14px",
                            lg: "16px",
                          },
                          lineHeight: {
                            xs: "23px",
                            lg: "26px",
                          },
                          px: "20px",
                        }}
                      >
                        {item?.content}
                      </Typography>
                    </Box>
                  </>
                );
              }
            )}
          </Box>
          <Box sx={{
            display:'flex',
            justifyContent:'center'
          }}>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid #fff",
              borderRadius: "7px",
              padding: "10px 50px",
              height: "45px",
              fontSize: "15px",
              // marginLeft: "10px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
              marginTop:'20px'
            }}
            onClick={() => navigate("/")}
          >
            Back to Home
          </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssistantGPT;
