import { configureStore } from "@reduxjs/toolkit";
import errorSlice from "./error.slice";
import ItinerarySlice from "./chatgpt/chatgpt";

export const store = configureStore({
  reducer: {
    error: errorSlice,
    Itinerary: ItinerarySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
