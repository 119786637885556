import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "AppRouter";
import { BrowserRouter } from "react-router-dom";
import "assets/main.css";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import AuthMiddleware from "middleware/AuthMiddleware";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <AuthMiddleware> */}
          <AppRouter />
          <Toaster reverseOrder={false} />
        {/* </AuthMiddleware> */}
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
