import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import Header from "components/Header/index";
import { useDispatch, useSelector } from "react-redux";
// import { getIpAddressAsync } from "redux/analytics/analytics";
// import { gmtTime } from "utils/gmtTime";
// import { windowInfo } from "utils/clientInfo";
import Footer from "components/Footer/index";
import Navbar from "components/Navbar/index";
import { useLocation } from "../../node_modules/react-router-dom/dist/index";
import _ from "lodash";
import Maintainance from "pages/Maintainance/index";
import PassWord from "pages/PassWord/index";
import { decrypt } from "utils/cryptojs";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [maintainance, setMaintainance] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(false);

  const pass = localStorage.getItem("item");

  // useMemo(async () => {
  //   const time = gmtTime();
  //   const browserIn = windowInfo();

  //   dispatch(getIpAddressAsync()).then((response) => {
  //     if (response?.payload?.ip) {
  //     }
  //   });
  //   if (window.navigator.geolocation) {
  //     window.navigator.geolocation.getCurrentPosition(
  //       (success) => {},
  //       (error) => {
  //         return;
  //       }
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (pass) {
      const decrptI = decrypt(pass);
      if (decrptI === "vartik@travel_item") {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }
  }, []);

  return maintainance ? (
    <Maintainance />
  ) : (
    <Box>
      {!passwordMatch ? (
        <PassWord />
      ) : (
        <>
          {/* <Header /> */}
          {/* {_.includes(pathname, "account") && userinfo?.token && <Navbar />} */}
          <Box sx={{ minHeight: "100vh" }}>{children}</Box>
          {/* <Footer /> */}
        </>
      )}
    </Box>
  );
};

export default MainLayout;
