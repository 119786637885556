import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { CaretLeftOutlined, RocketOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import GoWith1 from "../../../assets/gowith1.png";
import GoWith2 from "../../../assets/gowith2.png";
import GoWith3 from "../../../assets/gowith3.png";
import GoWith4 from "../../../assets/gowith4.png";
import bgImage from "../../../assets/bg.png";

const GoingWith = () => {
  const navigate = useNavigate();
  const strng = window.location.search;
  const params = new URLSearchParams(strng);

  const [gowith, setGowith] = useState({
    gowith: "",
    destination: "",
    days: null,
  });

  useEffect(() => {
    if (params) {
      const destination = params.get("destination");
      const days = params.get("days");
      setGowith({
        ...gowith,
        destination,
        days,
      });
    }
  }, [params]);

  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "1200px",
            padding: "40px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CaretLeftOutlined
              style={{ color: "#fff", fontSize: "25px" }}
              onClick={() => navigate("/")}
            />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "22px",
                fontWeight: 500,
                marginLeft: "450px",
              }}
            >
              Who are you going with?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <Grid
              container
              sx={{
                width: "1000px",
              }}
            >
              {[
                {
                  image: GoWith1,
                  label: "Family",
                },
                {
                  image: GoWith2,
                  label: "Couple",
                },
                {
                  image: GoWith3,
                  label: "Friends",
                },
                {
                  image: GoWith4,
                  label: "Solo",
                },
              ].map((r) => {
                return (
                  <Grid item lg={6}>
                    <Box
                      sx={{
                        height: "230px",
                        backgroundImage: `url(${r.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `${
                          gowith.gowith === r?.label ? "2px" : "1px"
                        } solid ${
                          gowith.gowith === r?.label ? "#fad033" : "#fff"
                        }`,
                        margin: "6px",
                        "&:hover": {
                          border: "2px solid #fad033",
                        },
                      }}
                      onClick={() => {
                        setGowith({
                          ...gowith,
                          gowith: r?.label,
                        });
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "22px",
                          fontWeight: 500,
                        }}
                      >
                        {r?.label}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#6d94a3",
                color: "#fff",
                border: "1px solid #D9D9D9",
                borderRadius: "36px",
                padding: "10px 31px",
                height: "45px",
                marginTop: "15px",
                "&:hover": {
                  backgroundColor: "#6d94a3",
                  color: "#fff",
                  border: "1px solid #D9D9D9",
                },
              }}
              startIcon={<RocketOutlined />}
              onClick={() =>
                navigate(
                  `/trip-type?destination=${gowith.destination}&days=${gowith.days}&gowith=${gowith.gowith}`
                )
              }
              disabled={!gowith?.gowith}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoingWith;
