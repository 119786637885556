import React, { useState } from "react";
import BannerImage from "assets/banner.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Container,
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { inputstyle } from "assets/makestyles/input";
import { EnterOutlined } from "@ant-design/icons";
import { addPushAssistent, assisstentAsync } from "redux/chatgpt/chatgpt";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import BarProgress from "components/BarProgress/index";
import { config } from "config/config";

const Assistant = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const downsm = useMediaQuery(theme.breakpoints.down("sm"));
  const inputstyleIn = inputstyle();

  const [prompt, setPrompt] = useState("");

  const { assisstentLoader, assisstentIn } = useSelector(
    (state) => state.Itinerary
  );

  const handleAssistent = (e) => {
    e.preventDefault();
    if (Boolean(prompt)) {
      const _userPrompt = {
        role: "user",
        content: _.startCase(prompt),
      };
      let _value = _.map(assisstentIn, (item) => item);
      _value = _.reverse(_value);
      dispatch(
        assisstentAsync({
          _old: _value,
          prompt,
        })
      );
      dispatch(addPushAssistent(_userPrompt));
      setPrompt("");
    }
  };

  return (
    <>
      <Container
        sx={{ p: "0px !important", m: "0px !important" }}
        maxWidth={false}
      >
        <LazyLoadImage
          alt={"travel picture"}
          effect="blur"
          src={BannerImage}
          width="100%"
          style={{
            objectFit: "cover",
            height: downsm ? "150px" : "250px",
          }}
        />
      </Container>
      <Container maxWidth="md">
        <form onSubmit={handleAssistent}>
          <TextField
            placeholder="Ask anything about the country"
            fullWidth
            sx={{
              border: "1px solid white",
              backgroundColor: "#2e2e33",
              borderRadius: "6px",
              mt: "30px",
            }}
            value={prompt}
            className={inputstyleIn.root}
            onChange={(e) => setPrompt(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {Boolean(prompt) && (
                    <IconButton onClick={handleAssistent}>
                      <EnterOutlined
                        style={{
                          color: "white",
                          fontSize: "25px",
                        }}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </form>
        {assisstentLoader && (
          <Box sx={{ mt: 2 }}>
            <BarProgress />
          </Box>
        )}
        {_.map(
          [
            ...assisstentIn,
            {
              role: "assistant",
              content:
                `Hello! How may I assist you today? As an assistant, I have extensive knowledge about ${config.LOCATION}. Just ask me anything about ${config.LOCATION}.`,
            },
          ],
          (item, index) => {
            const _iswho = Boolean(item.role === "assistant");
            const _length = Boolean(item?.content?.length <= 94);
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "#2e2e33",
                    px: "16px",
                    py: "20px",
                    mt: "20px",
                    borderRadius: "10px",
                    // flexDirection: _iswho && "row-reverse",
                    alignItems: _length && "center",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#1d1e20", width: 60, height: 60 }}>
                    {_iswho ? "AI" : "YOU"}
                  </Avatar>
                  <Typography
                    variant="h6"
                    sx={{
                      // textAlign: _iswho && "justify",
                      color: "#dadadb",
                      fontWeight: "500",
                      fontSize: {
                        xs: "14px",
                        lg: "16px",
                      },
                      lineHeight: {
                        xs: "23px",
                        lg: "26px",
                      },
                      px: "20px",
                    }}
                  >
                    {item?.content}
                  </Typography>
                </Box>
              </>
            );
          }
        )}
      </Container>
    </>
  );
};

export default Assistant;
