import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MaintainanceImage from "../../assets/maintainance.png";
import { LinkedinOutlined } from "@ant-design/icons";

const Maintainance = () => {
  return (
    <Container
      sx={{
        p: 0,
        m: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <LazyLoadImage
          id="round-image"
          alt={"travel picture"}
          effect="blur"
          src={MaintainanceImage}
          width="50px"
          style={{
            color: "white",
            objectFit: "cover",
            //   height: "250px",
            marginBottom: "20px",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: "#dadadb",
            fontWeight: "900",
            fontSize: {
              xs: "22px",
              lg: "35px",
            },
          }}
        >
          🗜️ Site is under maintainance
        </Typography>
        <Typography
          variant="h6"
          sx={{
            width: {
              lg: "800px",
            },
            px: {
              xs: "20px",
              md: "0px",
            },
            textAlign: "center",
            color: "#595a5b",
            fontWeight: "500",
            fontSize: {
              xs: "14px",
              lg: "16px",
            },
            py: "10px",
            lineHeight: {
              xs: "23px",
              lg: "26px",
            },
          }}
        >
          Our team is diligently working to ensure that our website is updated
          with the latest software, optimized for speed, and secure for your
          use. We want to provide our visitors with a seamless and enjoyable
          experience, and we believe that the maintenance work we are performing
          will help achieve this goal.
        </Typography>
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffc500",
            color: "black",
            fontSize: "10px",
            px: "13px",
            py: "5px",
            fontWeight: "900",
            borderRadius: "5px",
            mr: "10px",
            mt: "10px",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={() =>
            window.open("https://www.linkedin.com/company/chartamio", "_blank")
          }
        >
          <LinkedinOutlined style={{ marginRight: "10px", fontSize: "20px" }} />{" "}
          Follow us on Linkedin
        </Button>
      </Box>
    </Container>
  );
};

export default Maintainance;
