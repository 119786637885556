import MainLayout from "layouts/MainLayout";
// import HomePage from "pages/HomePage";
import React, { useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emptyErrorMessage } from "redux/error.slice";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import Logout from "pages/Logout";
import Assistant from "pages/Assistant/index";
import HomePage from "pages/GenerateScreens/HomePage/index";
import GoingWith from "pages/GenerateScreens/GoingWith/index";
import TripType from "pages/GenerateScreens/TripType/index";
import ItineraryResponse from "pages/GenerateScreens/ItineraryResponse/index";
import AssistantGPT from "pages/AssistantGPT/index";

const AppRouter = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.error);

  useMemo(() => {
    if (error) {
      toast.error(error, toastoptions);
      dispatch(emptyErrorMessage());
    }
  }, [error]);

  return (
    <MainLayout>
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/going-with" element={<GoingWith />} />
        <Route path="/trip-type" element={<TripType />} />
        <Route path="/itinerary" element={<ItineraryResponse />} />
        <Route path="/assistant" element={<AssistantGPT />} />
        <Route path="/travel-assistant" element={<Assistant />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </MainLayout>
  );
};

export default AppRouter;
