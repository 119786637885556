import React, { useEffect, useState } from "react";
import {
  Box,
  Skeleton,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { _path } from "config/path";
import ItineraryItem from "components/ItineraryItem/index";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../assets/bg.png";
import { getItineraryAsync } from "redux/chatgpt/chatgpt";

const ItineraryResponse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const strng = window.location.search;
  const params = new URLSearchParams(strng);

  const [itinary, setItinary] = useState({
    gowith: "",
    destination: "",
    days: null,
    triptype: "",
  });

  const { itineraryLoader, itinerary } = useSelector(
    (state) => state.Itinerary
  );
  const [_downloadLoader, set_downloadLoader] = useState(false);

  const handleDownloadPDF = async () => {
    try {
      set_downloadLoader(true);
      const response = await fetch(`${_path}/generate-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: itinerary }),
      }); // Replace with your backend URL
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a temporary link element and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `${_.toLower(
        _.replace(itinerary?._extract?.title, /\s/g, "_")
      )}.pdf`;

      link.click();

      // Clean up the temporary URL
      URL.revokeObjectURL(url);
      set_downloadLoader(false);
    } catch (error) {
      set_downloadLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (params) {
      const destination = params.get("destination");
      const days = params.get("days");
      const gowith = params.get("gowith");
      const triptype = params.get("triptype");
      console.log(destination, days, gowith, triptype);
      if (destination && days && gowith && triptype) {
        setItinary({
          destination,
          days,
          gowith,
          triptype,
        });
        dispatch(
          getItineraryAsync({
            location: destination,
            days: Number(days),
            trip: gowith,
            filter: triptype,
          })
        );
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "1202px",
            height: "80vh",
            overflow: "scroll",
            borderRadius: "5px",
            opacity: 4,
            backgroundColor: "#585858b7",
            marginTop: "50px",
          }}
        >
          {itineraryLoader && (
            <Box
              sx={{
                padding: "15px",
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffffad",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Just a sec while we chart your course! So no refreshing—or you'll miss the boarding call!
              </Typography>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={40}
                sx={{ borderRadius: "5px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={80}
                sx={{ borderRadius: "5px", marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={60}
                sx={{ borderRadius: "5px", marginTop: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={60}
                sx={{ borderRadius: "5px", marginTop: "10px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={50}
                  sx={{ borderRadius: "5px", marginTop: "10px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={50}
                  sx={{
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginLeft: "15px",
                  }}
                />
              </Box>
            </Box>
          )}

          {!itineraryLoader && Boolean(itinerary?._extract) && (
            <Box>
              <ItineraryItem
                {...{
                  itinerary,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    padding: "10px 50px",
                    height: "45px",
                    fontSize: "15px",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                  }}
                  onClick={() => handleDownloadPDF()}
                  startIcon={
                    _downloadLoader && (
                      <CircularProgress color="inherit" size="20px" />
                    )
                  }
                >
                  Download PDF
                </Button>

                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    padding: "10px 50px",
                    height: "45px",
                    fontSize: "15px",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                  }}
                  onClick={() => navigate("/")}
                >
                  Back to Home
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ItineraryResponse;
