import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#595a5b",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "white",
    },
  },
}));

const BarProgress = () => {
  const classes = useStyles();
  return (
    <Box sx={{ width: "100%" }} className={classes.root}>
      <LinearProgress />
    </Box>
  );
};

export default BarProgress;
