import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import { DownOutlined } from "@ant-design/icons";
import TableCustom from "components/TableCustom";
import { config } from "config/config";

const AccordionCustom = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  const handleChange = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return data?.map((item, index) => {
    return (
      <Accordion
        key={index}
        expanded={expandedIndex === index}
        onChange={() => handleChange(index)}
      >
        <AccordionSummary expandIcon={<DownOutlined />}>
          <Typography sx={{ fontFamily: config.FONTFAMILY }}>
            {item?.day}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              mb: "15px",
              mt: "20px",
            }}
          >
            <TableCustom
              head={["Do", "Time", "Location", "Travel Time"]}
              body={item?.activities}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  });
};

export default AccordionCustom;
