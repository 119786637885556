import axios from "axios";
import { _path } from "config/path";
import { errorMessage } from "./error.slice";
import { isJson } from "utils/isJson";
/*
 * Axios Api Call Component
 * @type : GET POST PATCH DELETE
 * @api : Api Path
 * @payload : Payload that need to be sent to server
 * @toolkit: dispatch, fulfillWithValue, rejectWithValue
 */

const AxiosClient = async (
  type,
  api,
  payload,
  toolkit,
  content = "application/json",
  external = false
) => {
  const AxiosTypeString = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
  };

  let url = "";
  if (external) {
    url = api;
  } else {
    url = `${_path}${api}`;
  }
  return await axios({
    method: AxiosTypeString[type],
    url: url,
    data: payload,
    headers: {
      "Content-Type": content,
      Authorization: isJson(localStorage.getItem("travelguider"))
        ? JSON.parse(localStorage.getItem("travelguider"))?.token
        : null,
    },
  })
    .then((response) => {
      return toolkit.fulfillWithValue({
        success: true,
        ...response.data,
      });
    })
    .catch((error) => {
      toolkit.dispatch(errorMessage(error.response.data.message));
      return toolkit.rejectWithValue(error.response.data.message);
    });
};
export { AxiosClient };
