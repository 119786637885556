import React, { useState } from "react";
import {
  Container,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { inputstyle } from "assets/makestyles/input";
import { UnlockOutlined } from "@ant-design/icons";
import { encrypt } from "utils/cryptojs";

const PassWord = () => {
  const inputstyleIn = inputstyle();
  const [password, setPassword] = useState("");
  let password_original = "vartik@travel_item";

  const handlePassword = () => {
    if (password_original === password) {
      localStorage.setItem("item", encrypt(password_original));
      window.location.reload(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <TextField
        placeholder="Password"
        fullWidth
        sx={{
          border: "1px solid white",
          backgroundColor: "#2e2e33",
          borderRadius: "6px",
        }}
        className={inputstyleIn.root}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        type="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  handlePassword();
                }}
              >
                <UnlockOutlined
                  style={{
                    color: "white",
                    fontSize: "25px",
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
};

export default PassWord;
