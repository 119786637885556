import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import _ from "lodash";
import AccordionCustom from "components/AccordionCustom";
import { config } from "config/config";

const ItineraryItem = ({ itinerary }) => {
  return (
    Boolean(itinerary?._extract) && (
      <Box
        sx={{
          px: "16px",
          py: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#dadadb",
            fontWeight: "800",
            fontSize: {
              xs: "20px",
              lg: "25px",
            },
            lineHeight: "35px",
            display: "flex",
            alignItems: "center",
            fontFamily: config.FONTFAMILY,
          }}
        >
          🪧 {itinerary?._extract?.title}{" "}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#d3d4d4",
            fontWeight: "400",
            fontSize: {
              xs: "14px",
              lg: "16px",
            },
            pt: "10px",
            lineHeight: {
              xs: "23px",
              lg: "26px",
            },
            mb: "15px",
            fontFamily: config.FONTFAMILY,
          }}
        >
          {itinerary?._extract?.description}
        </Typography>
        <AccordionCustom data={itinerary?._extractIn} />
      </Box>
    )
  );
};

export default ItineraryItem;
