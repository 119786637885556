import crypto from "crypto-js";
import { isJson } from "./isJson";
import { config } from "config/config";

export const decrypt = (data) => {
  try {
    const decrypt = crypto.AES.decrypt(data, config.SECRET_KEY).toString(
      crypto.enc.Utf8
    );
    if (isJson(decrypt)) {
      return JSON.parse(decrypt);
    } else {
      return decrypt;
    }
  } catch (err) {
    return;
  }
};

export const encrypt = (data) => {
  try {
    const encrypt = crypto.AES.encrypt(data, config.SECRET_KEY).toString();
    return encrypt;
  } catch (err) {
    return;
  }
};
