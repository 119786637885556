import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("item");
    localStorage.removeItem("travelguider");
    navigate("/");
    window.location.reload(false);
  });

  return;
};

export default Logout;
