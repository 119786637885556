import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import Logo from "../../../assets/logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";
import { RocketOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../assets/bg.png";

const HomePage = () => {
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(false);
  const [destination, setDestination] = useState({
    destination: "Abu Dhabi",
    days: 1,
  });
  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "1202px",
                height: "56px",
                borderRadius: "36px",
                opacity: 4,
                backgroundColor: "#ffffff69",
                marginTop: "50px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <LazyLoadImage
                  alt={"travel picture"}
                  src={Logo}
                  width="100px"
                  style={{
                    objectFit: "cover",
                    marginLeft: "20px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                {[
                  {
                    name: "Home",
                    route: "/",
                  },
                  {
                    name: "Assistant",
                    route: "/assistant",
                  },
                ].map((r) => {
                  return (
                    <NavLink
                      to={r.route}
                      style={{
                        textDecoration: "none",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        marginLeft: "25px",
                      }}
                    >
                      {r.name}
                    </NavLink>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              textTransform: "uppercase",
              color: "#fff",
              letterSpacing: "12px",
              fontSize: "28px",
              fontWeight: 300,
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            Welcome to the best trip planning experience!
          </Typography>
          <Grid container sx={{ marginTop: "120px", width: "1200px" }} lg={12}>
            <Grid item lg={4} sx={{ paddingTop: "40px" }}>
              <Typography
                sx={{
                  color: "#fff",
                  letterSpacing: "8px",
                  fontSize: "22px",
                  fontWeight: 300,
                }}
              >
                About
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: 300,
                }}
              >
                Introducing the world’s premier AI tool crafted exclusively for
                tourism boards, poised to revolutionize the B2B travel trade.
                This cutting-edge technology delivers real-time personalized
                itineraries and serves as an insightful AI assistant, enriching
                understanding for tourism professionals, enhancing agency
                collaboration, and optimizing marketing strategies. Leverage AI
                to refine operations and strengthen partnerships in the travel
                industry. Join us in defining the future of travel.
              </Typography>
            </Grid>
            <Grid
              item
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#4e4f50",
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: "36px",
                  padding: "10px 31px",
                  height: "50px",
                }}
                onClick={() => setAnchor(true)}
              >
                Create my first plan
              </Button>
            </Grid>
            <Grid
              item
              lg={4}
              sx={{
                display: "flex",
                // alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "transparent",
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: "36px",
                  padding: "10px 31px",
                  height: "50px",
                }}
                startIcon={<RocketOutlined />}
                onClick={() => navigate("/assistant")}
              >
                talk to AI
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={anchor}
        onClose={() => {
          setAnchor(false);
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "30px",
          },
        }}
      >
        <DialogContent sx={{ width: "600px", padding: "0px !important" }}>
          <Box sx={{ padding: "30px 30px 40px 30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              <CloseOutlined
                style={{ color: "#b6b6b6", cursor: "pointer" }}
                onClick={() => {
                  setAnchor(false);
                }}
              />
            </Box>
            <TextField
              placeholder="Destination"
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "36px",
                border: "1px solid #D9D9D9",
                ".MuiOutlinedInput-root": {
                  borderRadius: "36px",
                  height: "50px",
                },
                input: {
                  "&::placeholder": {
                    fontSize: "15px",
                    paddingLeft: "5px",
                  },
                },
              }}
              size="small"
              value={destination.destination}
              onChange={(e) => {
                const value = e?.target?.value?.replace(/[0-9]/g, "");
                setDestination({
                  ...destination,
                  destination: value,
                });
              }}
            />
            <TextField
              placeholder="Number of days"
              variant="outlined"
              sx={{
                width: "100%",
                borderRadius: "36px",
                border: "1px solid #D9D9D9",
                ".MuiOutlinedInput-root": {
                  borderRadius: "36px",
                  height: "50px",
                },
                input: {
                  "&::placeholder": {
                    fontSize: "15px",
                    paddingLeft: "5px",
                  },
                },
                marginTop: "15px",
              }}
              size="small"
              value={destination.days}
              onChange={(e) => {
                const value = e?.target?.value?.replace(/[a-zA-Z]/g, "");
                setDestination({
                  ...destination,
                  days: value,
                });
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#6d94a3",
                  color: "#fff",
                  border: "1px solid #D9D9D9",
                  borderRadius: "36px",
                  padding: "10px 31px",
                  height: "45px",
                  marginTop: "15px",
                  "&:hover": {
                    backgroundColor: "#6d94a3",
                    color: "#fff",
                    border: "1px solid #D9D9D9",
                  },
                }}
                startIcon={<RocketOutlined />}
                onClick={() =>
                  navigate(
                    `/going-with?destination=${destination.destination}&days=${destination.days}`
                  )
                }
                disabled={!destination.destination || !destination.days}
              >
                Next
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default HomePage;
